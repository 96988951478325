.SingleDatePicker {
  width: 100%;
}

.SingleDatePickerInput {
  outline: none !important;
  width: 100% !important;
}

.SingleDatePicker_picker {
  z-index: 9999999;
}

.SingleDatePicker_picker td {
  text-align: center !important;
}

.CalendarDay {
  vertical-align: middle;
}

.SingleDatePickerInput input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  padding: 0px;
  width: 100% !important;
  border-bottom: 1px solid #A6A6A6;
}

.SingleDatePickerInput input::placeholder {
  color:#A6A6A6 !important 
}

.SingleDatePickerInput input:focus {
  border-bottom-color: #0040AA;
}

.DateInput {
  width: 100%;
}

.SingleDatePickerInput_calendarIcon{
  padding: 0px;
  margin: 0px;
  position: absolute;
  right: 5px
}

.CalendarDay__selected {
  background: #0040AA !important;
  border-color: #0040AA;
}

.DateRangePickerInput_arrow {
  display: none !important;
}

.DateInput_fang {
  display: none !important;
}
