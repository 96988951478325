@import url(./seniors-first-commons/styles/_all.css);
.notification {
  background-color: #FBFBFB;
  border-radius: 30px;
  padding: 2em;
  position: relative; }

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline; }

.notification strong {
  color: currentColor; }

.notification code,
.notification pre {
  background: white; }

.notification pre code {
  background: transparent; }

.notification > .delete {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem; }

.notification .title,
.notification .subtitle,
.notification .content {
  color: currentColor; }

.react-autosuggest__input {
  font-family: "fieldwork";
  text-align: center;
  border: none;
  border-bottom: solid 2px #D8D8D8;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
  font-size: 1.143rem;
  width: 100%;
  cursor: pointer; }

.react-autosuggest__input[disabled] {
  background-color: #FBF2EC;
  border-color: #FBF2EC;
  box-shadow: none;
  color: #525860; }

.react-autosuggest__input::placeholder {
  color: rgba(54, 54, 54, 0.3); }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__suggestions-container {
  display: none;
  cursor: pointer;
  text-align: center; }

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 20rem;
  overflow: auto;
  cursor: pointer;
  color: #0052cc; }

.react-autosuggest__suggestions-list {
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none !important; }

.react-autosuggest__suggestion {
  cursor: pointer;
  text-transform: capitalize; }

.react-autosuggest__suggestion--highlighted {
  color: #0093FC;
  font-weight: bold; }

.select-list {
  max-height: 340px !important;
  margin-bottom: 1rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.rc-slider-disabled {
  background: transparent; }

.rc-slider-disabled .rc-slider-handle {
  background: #525860 !important;
  border-color: #525860 !important; }

.rc-slider-handle {
  background: #0080F5 !important;
  border-color: #26DCF8 !important;
  cursor: pointer; }

.full-content-caregiver {
  background-image: url("./seniors-first-commons/components/atoms/images/icons/Dots.svg"), linear-gradient(#00C2FF 0%, #0080F5 60.42%, #89C2F7 99.99%, #F8F8F8 100%) !important;
  background-attachment: fixed !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100% !important;
  min-height: 100vh !important;
  min-width: 100vw; }
