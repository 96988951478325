
.modalContainer {
  top: 350px !important;
  left: 100% !important
}

.modal {
  position: fixed !important;
  bottom: 70px !important;
  right: 15px !important; 
  max-height: 350px !important;
  height: 100% !important;
  width: 290px !important;
  background-color: #FcFcFc;
  border-radius: 4px !important;
  z-index: 99999;
  overflow: hidden !important;
}

.noMessagesWrapper {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%);
  text-align: center;
  width: 100%
}