.menu-list a {
  padding-top: 13px!important;
  padding-bottom: 13px!important;
  border-radius: 5px;
}

.custom-menu-item:hover{
  background-color: #e0f0ff!important;
}

.menu-list a.is-active {
  background-color: #0068e1!important;
  color: #fff!important;
}